<template>
  <b-card body-class="p-1rem m-0" class="mb-0 w-100 h-100">
    <div 
      class="max-height-area-options"
    >

      <div class="d-flex justify-content-between pb-2 mb-2 border-bottom">
        <div>
          <h5>Sesion Properties</h5>
          <span class="avenir-medium">{{ formatDateIA(new Date(history_images.created_at)) }}</span>
        </div>
        <b-img :src="history_images.response_server.data[0].url" class="img-one-title" @error="errorLoad(history_images.response_server.data[0])"/>
      </div>
      
      <div class="d-flex justify-content-between mb-1">
        <div>
          <span class="d-block avenir-medium">PROMPT</span>
          <span class="d-block">{{ history_images.original_prompt }}</span>
        </div>
       
        <div> 
          <b-dropdown class="avenir-medium dropdown-options-sidebar-history" variant="link" no-caret>
            <template #button-content>
              <feather-icon icon="MoreHorizontalIcon"></feather-icon>
            </template>
            <b-dropdown-item @click="editHistory()">Edit prompt</b-dropdown-item>
            <b-dropdown-item @click="copyAction(history_images.processed_prompt)">Copy prompt</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="collapse_full_prompt = !collapse_full_prompt">{{collapse_full_prompt ? 'Hide ' : 'View '}} Full Prompt</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="row mb-1">
          <b-collapse v-model="collapse_full_prompt" class="col-12">
            <span class="d-block avenir-medium">FULL PROMPT</span>
            <span class="d-block">{{ history_images.processed_prompt }}</span>
          </b-collapse>
        <div class="col-6" :class="{'mt-1': collapse_full_prompt}">
          <span class="d-block avenir-medium">MODEL</span>
          <span class="d-block">{{ history_images.data.model ? $t('search.ia_options').find((i) => i.value === history_images.data.model).label : '--'}}</span>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col-6">
          <span class="d-block avenir-medium">{{ history_images.data.model !== 'imagine' ? 'SIZE' : 'ASCPECT RATIO' }}</span>
          <span class="d-block">{{ history_images.data.model !== 'imagine' ? history_images.size_images : history_images.data.aspect_ratio}}</span>
        </div>
        <div class="col-6">
          <span class="d-block avenir-medium">NEGATIVE PROMPT</span>
          <span class="d-block"> {{ history_images.data.negative_prompt ? history_images.data.negative_prompt : '--' }}</span>
        </div>
      </div>

      <!-- Rest of options when model it is diferent of imagine -->
      <div v-if="history_images.data.model !== 'imagine'">
        <div 
          v-for="(section, index) in prompt_builder" 
          :key="index"
        >
          <div v-if="Object.keys(section.models).length > 0" class="mt-1 collapse-gray-area">
            <div
              @click="toggleCollapse(index)"
              class="d-flex justify-content-between align-items-center toggle-hover"
            >
              <div><strong>{{ section.label }}</strong></div>
              <div class="container-plus-less-icon" :ref="`${index}-prompt`">
                <span></span>
                <span></span>
              </div>
            </div>
            <b-collapse :id="index" v-model="is_open[index]">
              <b-row class="mt-1" v-if="index !== 'variant_image'">
                <div v-for="(options, inner_index) in section.models" :key="inner_index" class="col-6 mb-1">
                  <span class="d-block avenir-medium">{{ inner_index.toUpperCase() }}</span>
                  <span class="d-block">{{ options }}</span>
                </div>
              </b-row>
              <b-row v-else class="p-1">
                <div class="container-blob">
                  <b-img :src="section.models.image" class="img-blob"></b-img>
                </div>
              </b-row>
            </b-collapse>
          </div>
        </div>
      </div>

      <!-- Options when model its imagine -->
      <div v-else>
        <div  class="mt-1 collapse-gray-area">
          <div
            @click="toggleCollapse('options-imagine')"
            class="d-flex justify-content-between align-items-center toggle-hover"
          >
            <div><strong> Options </strong></div>
            <div class="container-plus-less-icon" :ref="`options-imagine-prompt`">
              <span></span>
              <span></span>
            </div>
          </div>
          <b-collapse id="options-imagine" v-model="is_open['options-imagine']">
            <b-row class="p-1 collapse-options-imagine">
              <div class="col-6 mb-2" v-for="(option, index) in options_imagine_model" :key="index">
                <div v-if="option.value !== null">
                  <span class="d-block avenir-medium mb-05">{{ option.title }}</span>
                  <div v-if="option.is_image && option.value.model"> 
                    <b-img  :src="getUrlCategory(option.value.model.category)" class="img"></b-img>
                    {{ option.value.model.name }}
                  </div>
                  <span v-else-if="option.is_resolution">{{ option.value === 0 ? 'Low quality' : 'high quality'}}</span>
                  <span v-else>{{ option.value }}</span>
                </div>
              </div>
            </b-row>
          </b-collapse>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="mt-footer">
        <b-button variant="blue-button" class="blue-button" @click="editHistory()">View / Edit</b-button>
      </div>   
    </template>
  </b-card>
</template>

<script>
import { formatDateIA } from '@/libs/utils/times';

import { 
  BRow,
  BCard,
  BCollapse,
  BImg,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  VBToggle,
} from 'bootstrap-vue';
import { getAssetUrl, getUrlCategory } from '@/libs/utils/urls';
import { getModelsImagine, getArtStyleImagine } from '@/libs/utils/others';

const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'siderRightHistory',
  directives: {
    'b-toggle': VBToggle
  },
  components: {
    BCard,
    BRow,
    BCollapse,
    BImg,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  props: {
    history_images: {
      type: Object,
    },
  },
  data() {
    return {
      formatDateIA,
      getUrlCategory,
      options_imagine_model: [
        {
          title: 'MODELS',
          is_image: true,
          value: {model: getModelsImagine().find(i => i.value === this.history_images.data.style_id)}
        },
        {
          title: 'ART STYLES',
          is_image: true,
          value: this.history_images.data.art_style ? {model: getArtStyleImagine().find(i => i.name === this.history_images.data.art_style)} : null
        },
        {
          title: 'HIGH RESOLUTION',
          is_resolution: true,
          value: this.history_images.data.high_res_results
        },
        {
          title: 'CFG SCALE',
          value: this.history_images.data.cfg
        },
        {
          title: 'STEP SCALE',
          value: this.history_images.data.steps
        },
        {
          title: 'SEED',
          value: this.history_images.data.seed
        }
      ],
      is_open: {},
      collapse_full_prompt: false,
      prompt_builder: {
        camera: {
          label: 'Camera',
          models: {}
        },
        art_style: {
          label: 'Art Style',
          models: {}
        },
        artist: {
          label: 'Artist',
          models: {}
        },
        scene: {
          label: 'Scene',
          models: {}
        },
        technical: {
          label: 'Technical',
          models: {}
        },
        modifiers: {
          label: 'Modifiers',
          models: {}
        },
        variant_image: {
          label: 'Variant Image',
          models: {}
        }
      },
    }
  },
  created() {
    this.formatPrompt();
  },
  methods: {
    errorLoad(img) {
      img.url = require("@/assets/images/dashboard/imgDefault.png");
    },
    formatPrompt() {
      if (this.history_images.type_request === 'create_image') {
        for (const value in this.history_images.data) {
          const evit = ['negative_prompt', 'prompt', 'model', 'height', 'width', 'number']
          if (this.history_images.data[value] && evit.indexOf(value) === -1 && this.history_images.data.model !== 'imagine') {
            const skeleton = this.$t('search.prompts_skeleton')[value]
            this.prompt_builder[skeleton[0]].models[skeleton[1]] = this.history_images.data[value];
          }
        }
      } else if (this.history_images.type_request === 'variant_image') {
        this.prompt_builder.variant_image.models.image = getAssetUrl(this.history_images.data.origin_image)
      }
    },
    getAssetUrlInner(url) {
      return `${getAssetUrl()}/${url}`
    },
    toggleCollapse(section) {
      this.is_open[section] = !this.is_open[section]
      let icon_plus_promp = this.$refs[`${section}-prompt`];
      if (!icon_plus_promp.length) icon_plus_promp = [icon_plus_promp]
      icon_plus_promp[0].classList.toggle("transition-icon-plus");
      icon_plus_promp[0].classList.toggle("transition-form");
    },
    editHistory() {
      this.$router.push({ name: 'brandme_AI', params: { section: 'image', category: 'txt_to_image', uuid: this.history_images.uuid}});
      this.$emit('edit_history')
    },
    copyAction(link) {
      this.$copyText(link).then(() => {
        loadToastificationContent().then((module) => {
          this.$toast({
            component: module.default,
            props: {
              title: this.$t('campaigns.copied'),
              icon: "CopyIcon",
              text: 'The prompt has been copied',
              variant: 'success'
            },
          });
        })
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.collapse-options-imagine {
  .img {
    width: 26px;
    height: 26px;
    border-radius: 0.6em;
    object-fit: cover;
    margin-right: 0.5em;
  }
  .mb-05 {
    margin-bottom: 0.5em;
  }
}
.container-blob {
  width: 100%;
  height: 120px;
  background-color: #DEE2E6;
  border-radius: 0.7em;
  position: relative;
  
  .img-blob {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.mt-footer {
  display: flex;
  justify-content: end;
}

.toggle-hover:hover{
  cursor: pointer;
}
.max-height-area-options{
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  .img-one-title {
    width: 42px;
    height: 42px;
    border-radius: 0.5em;
    object-fit: cover;
  }

  .dropdown-options-sidebar-history {
    margin-top: -0.8em !important;
  }
}
.collapse-gray-area {
  background: #F8F9FA;
  padding: 1rem ;
  border-radius: 0.7em;
}
.container-plus-less-icon {
  background: transparent;
  width: 30px;
  height: 30px;
  border: 0;
  position: relative;

  span {
    position: absolute;
    transition: 300ms;
    background: #999999;

    &:first-child {
      top: 25%;
      bottom: 25%;
      width: 10%;
      left: 45%;
    }
    &:last-child {
      left: 25%;
      right: 25%;
      height: 10%;
      top: 45%;
    }
  }
}
.transition-icon-plus {
  span {
    transform: rotate(90deg);

    &:last-child {
      left: 50%;
      right: 50%;
    }
  }
}
</style>
